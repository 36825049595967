import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";

export default () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const itemStyle = {
    color: '#7e7e7e',
    padding: '10px 15px',
    cursor: 'pointer',
    borderRadius: '10px',
    fontSize: '14px'
  }

  const selectedStyle = {
    // background: '#fef5eb',
    // color: '#f88700'
    background: '#F3FBFF',
    color: '#0280c6'
  }

  const onItemClicked = (name) => {
    if (!pathname?.includes(name)) {
      navigate(`${name}`);
    }
  }

  return (
    <div style={{
      display: 'flex',
      // flexDirection: 'column',
      rowGap: '20px',
      // padding: '20px 5px',
      background: '#ffffff',
      // borderRight: '1px solid #C2C9D1'
    }}>
      <div 
        style={{
          ...itemStyle,
          ...(pathname?.includes('builder') ? selectedStyle : {})
        }}
        onClick={() => onItemClicked('builder')}
      >
        {'Assessment Builder'}
      </div>
      <div 
        style={{
          ...itemStyle,
          ...(pathname?.includes('reports') ? selectedStyle : {})
        }}
        onClick={() => onItemClicked('reports')}
      >
        {'Assessment Reports'}
      </div>
    </div>
  )
}