import React from 'react';

export default () => {

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <div style={{
        color: '#565656',
        fontSize: '24px',
        fontWeight: 'bold'
      }}>
        {'Use the navigation above to start working with Assessments!'}
      </div>
    </div>
  )
}