import React, { useState } from "react";
import { Modal, Button } from '@prosolve/common-components-ui'
import RequestShipmentForm from './RequestShipmentForm';
import FulfillRequestForm from "./FulfillRequestForm";
import RecipientForm from "./RecipientForm";
import useFetch from '../../../hooks/useFetch';
import useForm from '../../../hooks/useForm';

export default ({
  shipment,
  onClose,
  updateShipmentData
}) => {
  const {
    id,
    shipmentStatus,
  } = shipment;

  const formSchema = shipmentStatus === 'Waiting' ?
    {
      requiredFields: ['contactName', 'contactEmail', 'contactPhone', 'address', 'city', 'state', 'zip', 'requestedDate', 'requester', 'desiredArrivalDate' ],
      optionalFields: ['quantity', 'earliestArrivalDate', 'shippingInstructions'],
      initData: shipment
    }
  : shipmentStatus === 'Request Shipment' ?
    {
      requiredFields: ['unitsDelivered', 'shipmentCosts', 'shippedDate'],
      optionalFields: ['trackingNumber'],
      initData: shipment
    }
  : (shipmentStatus === 'Shipment Sent' || shipmentStatus === 'Shipment Completed') ?
    {
      requiredFields: ['receivedDate'],
      optionalFields: ['receivedName', 'missingNote'],
      initData: shipment
    }
  : {}

  const formData = useForm(formSchema)

  const [isBusy, setIsBusy] = useState(false);

  const fetch = useFetch();

  const updateShipmentReceived = async () => {
    setIsBusy(true)
    try {

      const urlEndpoint = shipmentStatus === 'Waiting' ?
        'shipments/shipment/request'
      : shipmentStatus === 'Request Shipment' ?
        'shipments/shipment/fulfill'
      : shipmentStatus === 'Shipment Sent' ?
        'shipments/shipment/received'
      : ''

      const updateData = { ...formData.values, id };

      if (shipmentStatus === 'Waiting') {
        updateData.shipmentStatus = 'Request Shipment';
      } else if (shipmentStatus === 'Request Shipment') {
        updateData.shipmentStatus = 'Shipment Sent';
      } else if (shipmentStatus === 'Shipment Sent') {
        updateData.shipmentStatus = 'Shipment Completed';
      }

      const response = await fetch(
        urlEndpoint, 
        { 
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData)
        }
      )
      const jsonData = await response.json();
      if (jsonData.error) {
        // TODO: Show some kind of error message
        console.log(jsonData.error)
      } else {
        updateShipmentData(updateData)
        onClose()
      }
      
    } catch (e) {
      console.log(e)
      // setNoData(true)
      // setShipmentData(undefined)
    }
    setIsBusy(false)
  }

  const onClickSubmit = () => {
    console.log(formData.values)
    if (formData.isValid()) {
      updateShipmentReceived()
    }
  }

  const footer = (
    <div style={{ 
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: '10px',
      padding: '10px'
    }}>
      {
        Boolean(isBusy) &&
        <div>{'Updating...'}</div>
      }
      <Button onClick={onClose}>
        {shipmentStatus === 'Shipment Completed' ? 'Close' : 'Cancel'}
      </Button>
      { shipmentStatus !== 'Shipment Completed' &&
        <Button onClick={onClickSubmit} $colored={true}>
          {'Submit'}
        </Button>
      }
    </div>
  )

  const formatTitle = () => {
    if (shipmentStatus === 'Waiting') {
      return 'Request Shipment';
    } else if (shipmentStatus === 'Request Shipment') {
      return 'Fulfill Shipment Request';
    } else if (shipmentStatus === 'Shipment Sent') {
      return 'Confirm Receipt of Material';
    } else if (shipmentStatus === 'Shipment Completed') {
      return 'Receipt of Material Confirmation';
    } else {
      return '';
    }
  }

  return (
    <Modal title={formatTitle()} onClose={onClose} footer={footer}>
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        {
          shipmentStatus === 'Waiting' ?
            <RequestShipmentForm formData={formData} />
          : shipmentStatus === 'Request Shipment' ?
            <FulfillRequestForm formData={formData} />
          : shipmentStatus === 'Shipment Sent' ?
            <RecipientForm formData={formData} shipment={shipment} />
          : shipmentStatus === 'Shipment Completed' ?
            <RecipientForm formData={formData} shipment={shipment} isReadOnly={true} />
          : <></>
        }
      </div>
    </Modal>
  )
}