import React, { useState, useEffect, useMemo } from "react";
import IntroEditor from "./IntroEditor";
import QuestionsLeftSelector from "./QuestionsEditor/QuestionsLeftSelector";
import QuestionEditor from "./QuestionsEditor/QuestionEditor";

export default ({
  assessment,
  editedAssessment, 
  setEditAssessment,
  isReadOnly
}) => {
  const {
    backgroundImage,
    intro,
    updatedTime,
    questions = []
  } = assessment;

  const onIntroChanged = (intro) => {
    const modifiedIntro = { ...intro };

    const backgroundImage = modifiedIntro.backgroundImage;

    delete modifiedIntro.backgroundImage;

    const updatedAssessment = {
      ...editedAssessment,
      backgroundImage,
      intro: modifiedIntro
    }

    /*
     * Keeping in case theres a case for this. But below logic not really relevant for updating since mongodb will simply skip over an object not found
     *
        let hasIntro = false;
        Object.keys(modifiedIntro).forEach(introKey => {
          if(Boolean(modifiedIntro[introKey])) {
            hasIntro = true;
          }
        });
        if (!hasIntro) {
          delete updatedAssessment.intro;
        }
    */
    
    setEditAssessment(updatedAssessment)
  } 

  const onQuestionsChanged = (editedQuestions) => {
    const updatedAssessment = { 
      ...editedAssessment,
      questions: editedQuestions
    }
    setEditAssessment(updatedAssessment)
  } 

  let highestQuestionId = 0;
  questions.forEach(({ id }) => {
    const idNum = Number(id);
    if (idNum > highestQuestionId) {
      highestQuestionId = idNum;
    }
  });

  const startingNewQuestionId = highestQuestionId + 1;

  const [newQuestionId, setNewQuestionId] = useState(startingNewQuestionId)

  const initQuestions = questions.map(qObj => ({ ...qObj }));

  const [editedQuestions, setEditedQuestions] = useState(initQuestions)

  const modifiedObjects = isReadOnly ? {} : useMemo(() => {
    const modObjMap = {};

    if (questions.length !== editedQuestions.length) {
      modObjMap.all = true;
    }

    const hasIntroObjDiff = JSON.stringify(assessment.intro || {}) !== JSON.stringify(editedAssessment.intro || {});
    const hasBackgroundDiff = assessment.backgroundImage !== editedAssessment.backgroundImage;
    const hasIntroDiff = (hasIntroObjDiff || hasBackgroundDiff);

    if (hasIntroDiff) {
      modObjMap.intro = true;
    }

    let hasSortDiff = false;
    editedQuestions.forEach((editedObj) => {
      const { id } = editedObj;
      const qObj = questions.find(({ id: origId }) => origId === id) || {};
      // Don't account for sort in this diff
      const hasDiff = JSON.stringify({ ...qObj, sort: '' }) !== JSON.stringify({ ...editedObj, sort: '' });
      if (hasDiff) {
        modObjMap[id] = true;
      }
      const sortDiff = qObj.sort !== editedObj.sort;
      if (sortDiff) {
        hasSortDiff = true;
      }
    });

    if (hasSortDiff) {
      modObjMap.sort = true;
    }

    return modObjMap;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedQuestions, editedAssessment]);

  useEffect(() => {
    onQuestionsChanged(editedQuestions)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedQuestions])

  const addNewQuestion = (category = '') => {
    const newEmptyQuestionObj = {
      id: newQuestionId,
      question: '',
      category,
      type: 'text',
      sort: newQuestionId // We can safely use this for initial sort value since its guarenteed to always be higher then any other existing sort value
    }
    const updatedQuestions = [...editedQuestions];
    updatedQuestions.push(newEmptyQuestionObj)
    
    setEditedQuestions(updatedQuestions)

    setNewQuestionId(newQuestionId + 1)

    setSelectedQuestion(newEmptyQuestionObj)
  }

  const addNewCategory = (catName = '') => {
    addNewQuestion(catName)
  }

  const updateCategory = (currentCatName, newCatName = '') => {
    const updatedQuestions = [...editedQuestions];

    updatedQuestions.forEach(item => {
      if (item.category === currentCatName) {
        item.category = newCatName;
      }
    });

    setEditedQuestions(updatedQuestions)
  }

  const moveAndSortQuestions = (fromIndex, toIndex, category, fromRange = 1) => {
    const updatedQuestions = [...editedQuestions];

    // Remove the item from its current position
    const items = updatedQuestions.splice(fromIndex, fromRange);

    items.forEach(item => {
      item.category = category || '';
    });
    
    const finalToIndex = fromIndex < toIndex ? (toIndex - fromRange) : toIndex;
    
    // Insert the item at the new position
    updatedQuestions.splice(finalToIndex, 0, ...items);


    // Next simply renumber the sort field to match the index (+1 to avoid a falsy) in the array
    updatedQuestions.forEach((item, index) => {
      item.sort = (index + 1)
    });

    setEditedQuestions(updatedQuestions)
  }

  const onQuestionChanged = (question = {}) => {
    const qIndex = editedQuestions.findIndex(({ id }) => id === question.id)

    if (qIndex > -1) {
      const qObj = editedQuestions[qIndex];

      const updatedQObj = { ...qObj, ...question};

      const updatedQuestions = [...editedQuestions];
      updatedQuestions[qIndex] = updatedQObj;

      const updatedType = updatedQObj.type;
      if (['text','stars'].includes(updatedType) && updatedQObj.answerOptions) {
        delete updatedQObj.answerOptions;
      }

      setEditedQuestions(updatedQuestions)
    }
  }

  const onQuestionDeleted = (question = {}) => {
    const qIndex = editedQuestions.findIndex(({ id }) => id === question.id)
    if (qIndex > -1) {
      const updatedQuestions = [...editedQuestions];
      updatedQuestions.splice(qIndex, 1)
      setEditedQuestions(updatedQuestions)
    }
  }

  const [selectedQuestion, setSelectedQuestion] = useState()

  const onQuestionSelected = (questionObj) => {
    setSelectedQuestion(questionObj)
  }

  const questionInView = selectedQuestion && selectedQuestion.id ? editedQuestions.find(({ id }) => id === selectedQuestion.id) : undefined;

  const onIntroSelected = () => {
    setSelectedQuestion(undefined)
  }

  return (
    <div style={{ margin: '0px 0px', flex: 1, display: 'flex', flexDirection: 'row' }}>

      <QuestionsLeftSelector 
        questions={editedQuestions}
        modifiedObjects={(updatedTime ? modifiedObjects : {})}
        onIntroSelected={onIntroSelected}
        onQuestionSelected={onQuestionSelected} 
        selectedQuestion={selectedQuestion} 
        addNewQuestion={addNewQuestion}
        addNewCategory={addNewCategory}
        updateCategory={updateCategory}
        moveAndSortQuestions={moveAndSortQuestions}
        isReadOnly={isReadOnly} 
      />

      <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          overflow: 'auto'
        }}>
      {
        questionInView ?
        <QuestionEditor
          key={questionInView.id}
          question={questionInView}
          isReadOnly={isReadOnly}
          onChange={onQuestionChanged}
          onDelete={onQuestionDeleted}
        />
        :
        <IntroEditor intro={intro} backgroundImage={backgroundImage} onChange={onIntroChanged} isReadOnly={isReadOnly} />
      }
      </div>


    </div>
  )
}