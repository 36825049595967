import { FaRegCircle, FaCircle } from "react-icons/fa";

export default ({
  total,
  selectedIndex
}) => {
  const CurrentCircle = <FaCircle style={{ color: '#e8e8e8'}} />
  const FilledCircle = <FaCircle style={{ color: '#b1b1b1'}} />
  const EmptyCircle = <FaRegCircle style={{ color: '#b1b1b1'}} />
  
  const dots = [];
  for(let i = 0; i < total; i++) {
   dots.push(selectedIndex === i ? CurrentCircle : i < selectedIndex ? FilledCircle : EmptyCircle)
  }

  return (
    <div style={{ display: 'flex', columnGap: '10px', fontSize: '14px'}}>
      {
        dots.map((dot, index) => 
          <div key={index}>
            {dot}
          </div>
        )
      }
    </div>
  )
}