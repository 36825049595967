import specialAnalyticsConfig from './processAnalyticsConfig.json'

/*
 * Config Format follows: 
 * - AssessmentName {}
 *   - questionId {}
 *     - array of groups to move data into []
 *       - { name (group name), answerIds (array of ids that should be placed in this group)}
 */

export default (assessmentName, analyticsData) => {
  if (analyticsData && assessmentName && specialAnalyticsConfig[assessmentName]) {
    const config = specialAnalyticsConfig[assessmentName];

    const {
      counts: countsOrig = {},
      questions: questionsOrig = []
    } = analyticsData;

    const counts = { ...countsOrig };
    const questions = questionsOrig.map(qObj => ({ ...qObj }));

    Object.keys(counts).forEach(questionId => {
      const countObj = counts[questionId];
      const groupsArray = config[questionId];

      if (countObj && groupsArray) {
        const newCountObj = {}

        groupsArray.forEach(groupObj => {
          const {
            name,
            answerIds
          } = groupObj;

          if (!newCountObj[name]) {
            newCountObj[name] = 0;
          }

          Object.keys(countObj).forEach(answerId => {
            if (answerIds.includes(Number(answerId))) {
              const answerIdCount = countObj[answerId];
              const incrementNumber = Number(answerIdCount) || 0;

              newCountObj[name] = (newCountObj[name] + incrementNumber);              
            }
          });
        }); 

        counts[questionId] = newCountObj;

        // replace question answerOptions with new groups
        const questionDataObj = questions.find(({ id }) => String(id) === String(questionId)) || {};
        questionDataObj.answerOptions = groupsArray.map(({ name }) => ({ id: name, answerOption: name }));
      }
    });


    return {
      counts,
      questions
    };
  } else {
    return analyticsData;
  }
}