import React, { useState, useEffect } from 'react';
import { LoadingIcon, IconButtonWrapper } from '@prosolve/common-components-ui'
import useFetch from '../../../hooks/useFetch';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

const Option = styled.div`
  font-size: 14px;
  font-weight: normal;
  padding: 8px 20px;
  cursor: pointer;
  background: ${props => props.$selected ? "#44ACE1" : "#ffffff"};
  color: ${props => props.$selected ? "#ffffff" : "#080808"};
  text-align: left;
  border-bottom: ${props => props.$hasBorderBottom ? '1px solid #dedede' : 'none'};
  &:hover {
    background: #44ACE1!important;
    color: #ffffff!important;
  }
`;

export default ({
  accountId,
  selectedImplementation,
  setSelectedImplementation,
}) => {
  const fetch = useFetch();

  const [isLoading, setIsLoading] = useState(true)
  const [implementations, setImplementations] = useState([])
  const [selectorBoxOpen, setSelectorBoxOpen] = useState(false)

  const fetchAccounts = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`accounts/implementations?accountId=${accountId}`, { method: 'GET' })
      const jsonData = await response.json();
      if (jsonData.error) {
        setImplementations([])
      } else {
        setImplementations(jsonData)
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e)
      setImplementations([])
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (accountId) {
      fetchAccounts();
    } else {
      setImplementations([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId])

  const selectedAccountDisplayName = selectedImplementation && selectedImplementation.Name ? selectedImplementation.Name : 'All';

  useEffect(() => {
    const offClickEvent = () => {
      setSelectorBoxOpen(false)
    }
    document.addEventListener('click', offClickEvent)

    return () => {
      document.removeEventListener('click', offClickEvent)
    }
  }, [])

  const getSelectorBox = () => {
    return (
      <div style={{
        position: 'absolute',
        background: '#ffffff',
        width: '400px',
        height: '200px',
        top: '0px', // 'calc(50% - 50px)',
        left: '0px',
        overflow: 'auto',
        border: '1px solid #c4c4c4',
        borderRadius: '5px',
        zIndex: '1',
        display: 'flex',
        flexDirection: 'column',
        // padding: '10px'
      }}>
        <div
          style={{
            height: '250px',
            border: '1px solid #c4c4c4',
            borderRadius: '5px',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            overflow: 'auto',
          }}
        >
        {
          isLoading
          ?
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}>
            <LoadingIcon />
          </div>
          :
          implementations.map((account) => {
            const { Id, Name } = account;
            return (
              <Option
                key={Id}
                $selected={selectedImplementation && selectedImplementation.Id === Id} 
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedImplementation(account);
                  setSelectorBoxOpen(false);
                }}
              >
                {Name}
              </Option>
            )
          })
        }
        </div>
      </div>
    )
  }

  return implementations && implementations.length > 1 ? (
    <div style={{
      width: '300px',
      display: 'flex',
      flexDirection: 'column',
      padding: '0px'
    }}>

      <div style={{
        color: '#565656',
        fontSize: '24px',
        fontWeight: 'bold',
        padding: '20px'
      }}>
        {'Implementation'}
      </div>

      <div
        style={{
          padding: '0px 10px 0px 20px',
          cursor: 'pointer',
          fontWeight: 'bold',
          fontSize: '20px',
          color: '#0280c6',
          position: 'relative',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <div 
          style={{ flex: 1 }} 
          onClick={(e) => { e.stopPropagation(); setSelectorBoxOpen(true); }}
        >
          {selectedAccountDisplayName}
        </div>
        {
          Boolean(selectedImplementation) &&
          <IconButtonWrapper
            onClick={(() => setSelectedImplementation(undefined))}
          >
            <FaTimes style={{ fontSize: '22px' }}  />
          </IconButtonWrapper>
        }
        {selectorBoxOpen && getSelectorBox()}
      </div>
    </div>
  ) : <></>;
}