import React, { useState, useEffect } from 'react';
import Chevron from './Chevron';
import styled from 'styled-components';

const Item = styled.div`
  background: ${props => props.$selected ? '#F3FBFF' : '#ffffff'};
  color: ${props => props.$selected ? '#0280c6' : '#080808'};
  padding: 10px;
  border-bottom: 1px solid #c4c4c4;
  font-size: 16px;
  word-wrap: break-word;
  text-align: center;
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
`;

export default ({
  questions = [],
  currentQuestionId,
  changeQuestionId,
}) => {

  const currentQuestionIndex = questions.findIndex(({ id }) => String(id) === String(currentQuestionId)); 
  const currentQuestionDataObj = questions[currentQuestionIndex] || {}; 

  const {
    category = '',
    question = '',
  } = currentQuestionDataObj;

  const questionDisplay = `${category ? `${category}: ` : ''}${question}`;

  const onClickChevron = (dir) => {
    const newIndex = (dir === 'left') ? (currentQuestionIndex - 1) : (currentQuestionIndex + 1)

    const changeToQuestionDataObj = questions[newIndex];
    
    if (changeToQuestionDataObj) {
      const { id } = changeToQuestionDataObj;
      changeQuestionId(id)
    }
  }

  const [questionsBoxOpen, setQuestionsBoxOpen] = useState(false)

  useEffect(() => {
    const offClickEvent = () => {
      setQuestionsBoxOpen(false)
    }
    document.addEventListener('click', offClickEvent)

    return () => {
      document.removeEventListener('click', offClickEvent)
    }
  }, [])

  const getQuestionBox = () => {
    return (
      <div style={{
        position: 'absolute',
        width: '800px',
        maxHeight: '200px',
        top: '-105px',
        left: 'calc(50% - 400px)',
        overflow: 'auto',
        border: '1px solid #c4c4c4',
        borderRadius: '5px',
      }}>
        {
          questions.map(({ id, category = '', question = '' }) => {
            return (
              <Item key={id} $selected={id === currentQuestionId} onClick={() => changeQuestionId(id)}>{`${category ? `${category}: ` : ''}${question}`}</Item>
            )
          })
        }
      </div>
    )
  }

  return (
    <div style={{
      position: 'relative',
      height: '100px',
      padding: '0px 20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Chevron direction='left' size='16px' onClick={() => onClickChevron('left')} isDisabled={!Boolean(questions[currentQuestionIndex - 1])} />
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }} >
        <div style={{ textAlign: 'center', cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); setQuestionsBoxOpen(true); }}>{questionDisplay}</div>
      </div>
      { questionsBoxOpen && getQuestionBox() }
      <Chevron direction='right' onClick={() => onClickChevron('right')} isDisabled={!Boolean(questions[currentQuestionIndex + 1])} />
    </div>
  )
}