import { useState } from 'react';

export default (schema) => {
  const {
    requiredFields = [],
    optionalFields = [],
    initData = {}
  } = schema;

  const fieldNames = [ ...requiredFields, ...optionalFields ];

  const initValues = {}
  fieldNames.forEach(fieldName => {
    initValues[fieldName] = initData[fieldName] || '';
  });

  const [values, setValues] = useState(initValues);
  const [missingValues, setMissingValues] = useState({})

  const clearMissingValuesProperties = (changedData) => {
    const updateMissingValues = { ...missingValues };
    Object.keys(changedData).forEach((key) => {
      if (Boolean(changedData[key])) {
        delete updateMissingValues[key];
      }
    })
    setMissingValues(updateMissingValues);
  }

  const setValuesWrapper = (data) => {
    setValues(data)
    clearMissingValuesProperties(data)
  }


  const isFormValid = () => {
    let isValid = true;
    const updateMissingValues = {};
    requiredFields.forEach(requiredField => {
      if (!values[requiredField]) {
        isValid = false;
        updateMissingValues[requiredField] = true;
      }
    });
    setMissingValues(updateMissingValues);
    return isValid;
  }

  return {
    values, 
    setValues: setValuesWrapper,
    missingValues,
    isValid: isFormValid
  }
};