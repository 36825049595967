import React from 'react';

export default ({
  percentage
}) => {

  return (
    <div style={{
      position: 'absolute',
      width: '100%',
      height: '10px',
      top: '0px',
      left: '0px',
      background: '#00000033'
    }}>
      <div style={{
        background: '#d2275a',
        width: `${percentage}%`,
        height: '10px',
      }}>
        &nbsp;
      </div>
    </div>
  )
}