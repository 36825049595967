import React from 'react';
import { useOutlet } from "react-router-dom";
import AssessmentMangerHome from './AssessmentMangerHome';
import AssessmentManagerTopNav from './AssessmentManagerTopNav';
// import AssessmentManagerLeftNav from './AssessmentManagerLeftNav';

export default () => {
  const outlet = useOutlet()

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      background: '#f5f5f5',
      overflow: 'hidden'
    }}>
      <AssessmentManagerTopNav />
      <div style={{
        flex: 1,
        display: 'flex',
        overflow: 'hidden'
      }}>
        {/* <AssessmentManagerLeftNav /> */}
        <div style={{
          flex: 1,
          display: 'flex',
          overflow: 'auto',
          overflowX: 'hidden'
        }}>
          {outlet || <AssessmentMangerHome />}
        </div>
      </div>
    </div>
  )
}