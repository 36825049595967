export default (questions) => {

  // const questionsWithCategories =  [ ...questions ];

  // const categories = {};

  // questionsWithCategories.forEach((question, index) => {
  //   const {
  //     category
  //   } = question;

  //   if (category && !categories[category]) {
  //     categories[category] = true;

      // const categoryObject = {
      //   id: category,
      //   isCategory: true,
      //   category
      // }

  //     questionsWithCategories.splice(index, 0, categoryObject)
  //   }
  // });

  const questionsWithCategories =  [];

  const categories = {}; //  = [];

  questions.forEach((questionOrig, index) => {
    // Clone original question object
    const question = { ...questionOrig };

    const {
      category
    } = question;

    // if (category && categories.indexOf(category) < 0) {
    if (category) {
      // categories.push(category)

      let categoryObject;

      if (!categories[category]) {
        categoryObject = {
          id: category,
          isCategory: true,
          category,
          children: [question]
        }
        categories[category] = categoryObject;
        questionsWithCategories.push(categoryObject)
      } else {
        categories[category].children.push(question)
      }

      

      // const categoryObject = {
      //   id: category,
      //   isCategory: true,
      //   category
      // }

      // questionsWithCategories.splice(index, 0, categoryObject)
    } else {
      questionsWithCategories.push(question)
    }
  });

  return {
    categories: Object.keys(categories),
    questionsWithCategories
  };
}