import React from 'react';
import AnswerText from './AnwerTypes/AnswerText';
import AnswerOptions from './AnwerTypes/AnswerOptions';
import AnswerMultiTextOptions from './AnwerTypes/AnswerMultiTextOptions';
import AnswerStars from './AnwerTypes/AnswerStars';
import Dots from './Dots';

export default ({
  questionObj = {},
  categoryInfoMap = {},
  answers,
  setAnswers,
  peformAutoNextAction,
}) => {
  const {
    id: questionId,
    category = '',
    question = '',
    image,
    type,
    starLabels,
    totalStars,
    isRow,
    answerOptions
  } = questionObj

  const catInfo = categoryInfoMap[category] || [];

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      paddingTop: '40px'
    }}>
      {
        category &&
        <div style={{ fontSize: '24px', marginBottom: '40px' }}>
        {category}
        </div>
      }
      <div style={{ fontSize: '24px', marginBottom: '20px', whiteSpace: 'pre-wrap', textAlign: 'center' }}>
        {question}
      </div>
      {
        image &&
          <img
            src={image}
            alt="question"
            style={{
              height: '200px',
              marginBottom: '20px'
            }}
          />
      }
      {
        category && catInfo && catInfo.length > 0 &&
        <div style={{ marginBottom: '20px' }}>
          <Dots total={catInfo.length} selectedIndex={catInfo.indexOf(questionId)} />
        </div>
      }
      <div style={{
        maxWidth: '50%',
        minWidth: '500px'
      }}>
        {
          type === 'text'
            ?
            <AnswerText
              questionId={questionId}
              answers={answers}
              setAnswers={setAnswers}
            />
            :
            (type === 'checkbox' || type === 'radio')
              ?
              <AnswerOptions
                questionId={questionId}
                type={type}
                answers={answers}
                setAnswers={setAnswers}
                options={answerOptions}
                peformAutoNextAction={peformAutoNextAction}
                isRow={isRow}
              />
              :
              type === 'multitext'
              ?
              <AnswerMultiTextOptions
                questionId={questionId}
                answers={answers}
                setAnswers={setAnswers}
                options={answerOptions}
                peformAutoNextAction={peformAutoNextAction}
                isRow={isRow}
              />
              :
              type === 'stars' ?
              <AnswerStars
                questionId={questionId}
                answers={answers}
                setAnswers={setAnswers}
                starLabels={starLabels}
                totalStars={totalStars}
              />
              :
              <></>
        }
      </div>
    </div>
  )
}