import React, { useState } from 'react';
import { useGlobalData } from '../../GlobalData';
import { TabButton } from '@prosolve/common-components-ui'

export default ({
  programData = {},
  type
}) => {
  const {
    endUserPrimarySatisfaction,
    endUserPrimarySatisfactionLabel,
    endUserSecondarySatisfaction,
    endUserSecondarySatisfactionLabel,
    studentPrimarySatisfaction,
    studentPrimarySatisfactionLabel,
    studentSecondarySatisfaction,
    studentSecondarySatisfactionLabel
  } = programData;

  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  const primarySatisfaction = type === 'facilitator' ? endUserPrimarySatisfaction : studentPrimarySatisfaction;
  const secondarySatisfaction = type === 'facilitator' ? endUserSecondarySatisfaction : studentSecondarySatisfaction;

  const primarySatisfactionLabel = type === 'facilitator' ? 
    (endUserPrimarySatisfactionLabel || 'End User Primary Satisfaction') : (studentPrimarySatisfactionLabel || 'Student Primary Satisfaction');
  const secondarySatisfactionLabel = type === 'facilitator' ? 
    (endUserSecondarySatisfactionLabel || 'End User Secondary Satisfaction') : (studentSecondarySatisfactionLabel || 'Student Secondary Satisfaction');

  const hasSummaryData = primarySatisfaction || secondarySatisfaction;

  const [activeDisplayType, setActiveDisplayType] = useState(primarySatisfaction ? 'primarySatisfaction' : (secondarySatisfaction ? 'secondarySatisfaction' : ''))

  const activeValue = activeDisplayType === 'primarySatisfaction' ? primarySatisfaction : (activeDisplayType === 'secondarySatisfaction' ? secondarySatisfaction : '');
  const activeLabel = activeDisplayType === 'primarySatisfaction' ? primarySatisfactionLabel : (activeDisplayType === 'secondarySatisfaction' ? secondarySatisfactionLabel : '');

  const hasMulti = Boolean(primarySatisfaction) && Boolean(secondarySatisfaction);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      marginTop: '20px',
      paddingBottom: '20px',
      borderBottom:  '1px solid #dedede'
    }}>
      {
        hasSummaryData 
        ?
        <div>
          {
            hasMulti &&
            <div style={{ 
              display: 'flex',
              flexDirection: isMobileView ? 'column' : 'row',
              rowGap: isMobileView ? '15px' : '0px',
              columnGap: isMobileView ? '0px' : '15px',
              marginBottom: '20px'
            }}>
              {
                Boolean(primarySatisfaction) &&
                <TabButton $selected={activeDisplayType === 'primarySatisfaction'} onClick={() => setActiveDisplayType('primarySatisfaction')}>
                  Primary Satisfaction
                </TabButton>
              }
              {
                Boolean(secondarySatisfaction) &&
                <TabButton $selected={activeDisplayType === 'secondarySatisfaction'} onClick={() => setActiveDisplayType('secondarySatisfaction')}>
                  Secondary Satisfaction
                </TabButton>
              }
            </div>
          }
          {
            <div style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <div style={{
                height: '100px',
                width: '100px',
                lineHeight: '100px',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '30px',
                color: '#565656',
                border: '2px solid #b2b2b2',
                borderRadius: '50%',
                flexShrink: 0
              }}>
                {`${activeValue}%`}
              </div>
              <div style={{
                marginLeft: '20px',
                fontWeight: 'bold',
                fontSize: '16px',
                color: '#565656'
              }}>
                {activeLabel}
              </div>
            </div>
          }
        </div>
        :
        <div style={{
          color: '#808080',
          fontSize: '14px',
        }}>
          No overall summary data found for this program.
        </div>
      }
    </div>
  )
}
