import React from 'react';
import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsExportData from 'highcharts/modules/export-data'
import HighchartsReact from 'highcharts-react-official';

HighchartsExporting(Highcharts)
HighchartsExportData(Highcharts)

const formatChartData = (questionDataObj, counts = {}, isPercentage) => {

  const {
    question = '',
    category = '',
    type,
    answerOptions = []
  } = questionDataObj;

  const questionDisplay = `${category ? `${category}: ` : ''}${question}`;

  let categories;
  let data = [];
  let total = 0;
  if (type === 'stars') {
    const starValues =  Object.keys(counts);

    categories = starValues;

    // data = starValues.map(starValue => (counts[starValue] || 0));

    starValues.forEach(starValue => {
      const answerCount = (counts[starValue] || 0);
      data.push(answerCount)
      total += answerCount;
    });

  } else {
    categories = answerOptions.map(({ answerOption }) => answerOption);

    // data = answerOptions.map(({ id }) => (counts[id] || 0));
    
    answerOptions.forEach(({ id }) => {
      const answerCount = (counts[id] || 0);
      data.push(answerCount)
      total += answerCount;
    });
  }

  if (isPercentage) {
    data = data.map(val => Math.round( ((val / total) * 100) * 100) / 100 )
  }

  return {
    chart: {
        type: 'column'
    },
    title: {
        text: questionDisplay,
        align: 'left'
    },
    xAxis: {
        categories,
        crosshair: true,
        accessibility: {
            description: 'Answers'
        }
    },
    yAxis: {
        min: 0,
        title: {
            text: isPercentage ? 'Percentage' : 'Count'
        }
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return `${this.x}: <b>${this.y}${isPercentage ? '%' : ''}</b>`;
      }
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: [
        {
            name: 'Answers',
            data
        }
    ]
  };
}

export default ({
  currentQuestionDataObj,
  currentQuestionCounts,
  isPercentage
}) => {

  const options = formatChartData(currentQuestionDataObj, currentQuestionCounts, isPercentage)

  // const chartCreated = (chart) => {
  //   console.log(chart)
  // }

  return (
    <div style={{
      // flex: 1,
      // width: '100%',
      // display: 'flex',
      // flexDirection: 'column',
      // alignItems: 'center',
      // justifyContent: 'center'
    }}>
      <HighchartsReact 
        highcharts={Highcharts} 
        options={options} 
        // callback={chartCreated} 
      />
    </div>
  )
}