import React, { useState, useEffect, useRef } from 'react';
import { LoadingIcon, TextInput, IconButtonWrapper } from '@prosolve/common-components-ui'
import useFetch from '../../../hooks/useFetch';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

const Option = styled.div`
  font-size: 14px;
  font-weight: normal;
  padding: 8px 20px;
  cursor: pointer;
  background: ${props => props.$selected ? "#44ACE1" : "#ffffff"};
  color: ${props => props.$selected ? "#ffffff" : "#080808"};
  text-align: left;
  border-bottom: ${props => props.$hasBorderBottom ? '1px solid #dedede' : 'none'};
  &:hover {
    background: #44ACE1!important;
    color: #ffffff!important;
  }
`;

export default ({
  selectedAccount, 
  setSelectedAccount
}) => {
  const fetch = useFetch();

  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [accounts, setAccounts] = useState([])
  const [selectorBoxOpen, setSelectorBoxOpen] = useState(false)

  const fetchAccounts = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`accounts?implementationsOnly=true${searchValue ? `&name=${searchValue}` : ''}`, { method: 'GET' })
      const jsonData = await response.json();
      if (jsonData.error) {
        setAccounts([])
      } else {
        setAccounts(jsonData)
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e)
      setAccounts([])
      setIsLoading(false);
    }
  }

  const selectedAccountDisplayName = selectedAccount && selectedAccount.Name ? selectedAccount.Name : 'All';

  const timeoutRef = useRef();
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      fetchAccounts();
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  useEffect(() => {
    const offClickEvent = () => {
      setSelectorBoxOpen(false)
    }
    document.addEventListener('click', offClickEvent)

    return () => {
      document.removeEventListener('click', offClickEvent)
    }
  }, [])

  const getSelectorBox = () => {
    return (
      <div style={{
        position: 'absolute',
        background: '#ffffff',
        width: '400px',
        height: '200px',
        top: '0px', // 'calc(50% - 50px)',
        left: '0px',
        overflow: 'auto',
        border: '1px solid #c4c4c4',
        borderRadius: '5px',
        zIndex: '2',
        display: 'flex',
        flexDirection: 'column',
        // padding: '10px'
      }}>
        <TextInput
          width='100%'
          value={searchValue}
          onClick={(e) => e.stopPropagation()}
          autoFocus={true}
          style={{ borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
          onChange={(val) => setSearchValue(val)}
          placeholder='Search Accounts'
        />
        <div
          style={{
            height: '250px',
            border: '1px solid #c4c4c4',
            borderRadius: '5px',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            overflow: 'auto',
          }}
        >
        {
          isLoading
          ?
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}>
            <LoadingIcon />
          </div>
          :
          accounts.length > 0
          ?
          accounts.map((account) => {
            const { Id, Name } = account;
            return (
              <Option
                key={Id}
                $selected={selectedAccount && selectedAccount.Id === Id} 
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedAccount(account);
                  setSelectorBoxOpen(false);
                }}
              >
                {Name}
              </Option>
            )
          })
          :
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            fontSize: '15px', 
            color: '#5a5a5a'
          }}>
            No accounts found
          </div>
        }
      </div>
      </div>
    )
  }

  return (

    <div style={{
      width: '300px',
      display: 'flex',
      flexDirection: 'column',
      padding: '0px'
    }}>

      <div style={{
        color: '#565656',
        fontSize: '24px',
        fontWeight: 'bold',
        padding: '20px'
      }}>
        {'Account'}
      </div>

      <div
        style={{
          padding: '0px 10px 0px 20px',
          cursor: 'pointer',
          fontWeight: 'bold',
          fontSize: '20px',
          color: '#0280c6',
          position: 'relative',
          display: 'flex',
          alignItems: 'center'
          // borderBottom: isSelected ? '2px solid #0280c6' : '2px solid transparent'
        }}
      >
        <div 
          style={{ flex: 1 }} 
          onClick={(e) => { e.stopPropagation(); setSelectorBoxOpen(true); }}
        >
          {selectedAccountDisplayName}
        </div>
        {
          Boolean(selectedAccount) &&
          <IconButtonWrapper
            onClick={(() => setSelectedAccount(undefined))}
          >
            <FaTimes style={{ fontSize: '22px' }}  />
          </IconButtonWrapper>
        }
        {selectorBoxOpen && getSelectorBox()}
      </div>


    </div>
  )
}