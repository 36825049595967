import { useContext, createContext } from 'react';

export default class GlobalData {
  isMobileView = undefined;

  setIsMobileView(val) {
    this.isMobileView = val;
  }
}

const gdContext = createContext(new GlobalData());

export const GlobalDataContext = gdContext;

export const useGlobalData = () => useContext(gdContext)