import React, { useEffect, useState } from "react";
import useForm from '../../../../hooks/useForm';
import { FormField } from '@prosolve/common-components-ui'
import AnswerOptionsEditor from "./AnswerOptionsEditor";
import { FaRegTrashAlt } from 'react-icons/fa';

export default ({
  question: questionObj = {},
  isReadOnly,
  onChange,
  onDelete
}) => {
  const formSchema = {
    requiredFields: ['id', 'question', 'type'],
    optionalFields: ['category', 'isRow'],
    initData: questionObj
  }

  const { answerOptions } = questionObj;

  const formData = useForm(formSchema)

  const [editedAnswerOptions, setEditedAnswerOptions] = useState(answerOptions)

  const onAnswerOptionsChanged = (updateEditedAnswerOptions) => {
    setEditedAnswerOptions(updateEditedAnswerOptions)
  }

  useEffect(() => {
    const changedValues = {
      ...formData.values
    }
    if (editedAnswerOptions) {
      changedValues.answerOptions = editedAnswerOptions;
    }

    onChange(changedValues)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.values, editedAnswerOptions])

  const currentQuestionType = formData.values.type;

  const typeOptions = [
    { value: 'radio', text: 'Radio'  },
    { value: 'checkbox', text: 'Checkbox' },
    { value: 'text', text: 'Text'  },
    { value: 'stars', text: 'Stars'  },
    { value: 'multitext', text: 'Multitext'  },
  ]

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #dedede',
      borderRadius: '5px',
      // marginBottom: '20px',
      position: 'relative'
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        borderBottom: '1px solid #dedede',
        background: '#f8f9fc',
        padding: '20px',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        // borderRadius: '5px',
        // marginBottom: '20px',
      }}>
        <div>
          <FaRegTrashAlt 
            onClick={() => !isReadOnly && onDelete(formData.values)}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              fontSize: '20px',
              cursor: isReadOnly ? 'default' : 'pointer',
              color: isReadOnly ? '#7E7E7E' : '#f85c5c'
            }}  
          />
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          columnGap: '15px',
          rowGap: '20px',
          marginBottom: '20px'
        }}>
          <FormField
            fieldId='question'
            type='text'
            label='Question'
            disabled={isReadOnly}
            style={{ flex: 1 }}
            formData={formData}
          />
          {/* <FormField
            fieldId='category'
            type='text'
            label='Category'
            disabled={isReadOnly}
            style={{ flex: 1 }}
            formData={formData}
          /> */}
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          columnGap: '30px',
          rowGap: '20px',
          // marginBottom: '20px'
        }}>
          <FormField
            fieldId='type'
            type='select'
            label='Type'
            disabled={isReadOnly}
            // style={{ width: '100px' }}
            formData={formData}
            options={typeOptions}
          />
          <FormField
            fieldId='isRow'
            type='checkbox'
            label='Is Row'
            disabled={isReadOnly}
            // style={{ flex: 1 }}
            formData={formData}
          />
        </div>
      </div>
      {
        ['radio','checkbox','multitext'].includes(currentQuestionType) &&
          <AnswerOptionsEditor
            answerOptions={answerOptions}
            onChange={onAnswerOptionsChanged}
            isReadOnly={isReadOnly}
            type={currentQuestionType}
          />
      }

    </div>
  )
}