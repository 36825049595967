import React from 'react';

export default ({
  recipientDetails = {}
}) => {
  const {
    name,
    email,
    phone,
    address,
    address2,
    city,
    state,
    zip
  } = recipientDetails;

  return (
    <div style={{
      flex: 1,
    }}>
      <div style={{
        fontSize: '15px',
        fontWeight: 'bold',
        marginBottom: '20px'
      }}>
        {'Shipping Details'}
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        color: '#565656',
        fontSize: '14px'
      }}>
        <div style={{
          display: 'flex',
          marginBottom: '15px'
        }}>
          <div style={{ width: '80px', color: '#080808', fontSize: '15px'}}>{'Name'}</div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px'
          }}>
            <div>{name}</div>
            { Boolean(email) && <div style={{ color: '#0280c6' }}>{email}</div> }
            { Boolean(phone) && <div>{phone}</div> }
          </div>
        </div>
        <div style={{
          display: 'flex'
        }}>
          <div style={{ width: '80px', color: '#080808', fontSize: '15px'}}>{'Address'}</div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px'
          }}>
            <div>{address || ''}</div>
            { Boolean(address2) && <div>{address2}</div> }
            <div>{`${city ? `${city},` : ''} ${state || ''} ${zip || ''}`}</div>
          </div>
        </div>
      </div>
    </div>
  )
}