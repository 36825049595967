import React from "react";
import { LoadingIcon } from '@prosolve/common-components-ui'
import styled from 'styled-components';

const CodeWrapper = styled.div`
  font-size: 14px;
  padding: 8px 20px;
  cursor: default;
  background: #ffffff;
  color: #080808;
  text-align: left;
  padding: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dedede; // ${props => props.$hasBorderBottom ? '1px solid #dedede' : 'none'};
`;

export default ({
  isLoading,
  filters,
  questCodes
}) => {

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: '15px',
      flex: 1,
      marginTop: '20px'
    }}>

      <div
        style={{
          height: '600px',
          border: '1px solid #c4c4c4',
          borderRadius: '5px',
          overflow: 'auto',
        }}
      >
        {
          filters.productType === 'Unprovisioned Codes' ?
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            fontSize: '15px', 
            color: '#5a5a5a'
          }}>
            Tons of available codes but currently not displaying due to massive data size 
          </div> 
          :
          isLoading
          ?
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}>
            <LoadingIcon />
          </div>
          :
          questCodes.length > 0
          ?
          questCodes
          .sort((a, b) => b.provisionGroupNumber - a.provisionGroupNumber)
          .map((questCode) => {
            const { 
              _id, 
              code,
              productType,
              provisionGroupNumber,
              redeemed
            } = questCode;

            return (
              <CodeWrapper
                key={_id}
                // $hasBorderBottom={index !== questCodes.length - 1 ? true : false}
              >
                <div style={{ flex: 1, fontSize: '16px', fontWeight: 'bold'}}>{code}</div>
                {
                  Boolean(productType) &&
                  <div style={{ display: 'flex', flexDirection: 'column', rowGap: '5px'}}>
                    <div style={{ fontWeight: 'bold', color: '#0280c6' }}>{`Provisioned Group Number: ${provisionGroupNumber}`}</div>
                    <div style={{ color: redeemed ? '#2a932a' : '#f8acac', fontWeight: redeemed ? 'bold' : 'normal' }}>{`Redeemed: ${redeemed ? 'True' : 'False'}`}</div>
                  </div>
                }
              </CodeWrapper>
            )
          })
          :
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            fontSize: '15px', 
            color: '#5a5a5a'
          }}>
            No quest codes found
          </div>
        }
      </div>

    </div>
  )
}