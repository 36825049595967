import React from 'react';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export default ({
  direction,
  onClick,
  isDisabled
}) => {
  const chevronWrapperStyle = {
    cursor: isDisabled ? 'default' : 'pointer',
    position: 'absolute',
    top: '50%',
    left: direction === 'left' ? '15px' : 'auto',
    right: direction === 'right' ? '15px' : 'auto',
    background: isDisabled ? '#7E7E7ECC' : '#7E7E7E',
    padding: '20px',
    borderRadius: '50%'
  }

  const chevronStyle = {
    color: isDisabled ? '#CECECECC' : '#FFFFFF',
    fontSize: '34px',
    position: 'relative',
    top: '1px',
    left: direction === 'left' ? '-3px' : 'auto',
    right: direction === 'right' ? '-3px' : 'auto',
  }

  return (
    <div style={chevronWrapperStyle} onClick={onClick} >
      {
        direction === 'left' 
        ?
        <FaChevronLeft style={chevronStyle} />
        :
        <FaChevronRight style={chevronStyle} />
      }
    </div>
  )
}