import React, { useState, useMemo } from 'react';
import Review from './Review';
import ReviewFilters from './ReviewFilters';

export default ({
  reviews = [],
  hasStarsFilter,
}) => {
  // Use memo prevents questionSet recalculation on filter changes
  const reviewsQuestionSet = useMemo(() => {
    const qs = [];
    reviews.forEach(({ questionsAndAnswers = [] }) => {
      questionsAndAnswers.forEach(({ question }) => {
        if (question && qs.indexOf(question) < 0) {
          qs.push(question)
        }
      });
    });
    return qs;
  }, [reviews]);

  const allQuestions = [
    'Would you like to write a testimonial for our website?',
    'What did you personally gain from attending the event?',
    'What was your favorite part of the experience?',
    'How could we improve our services?'
  ]

  const availableQuestions = allQuestions.filter(val => reviewsQuestionSet.indexOf(val) > -1);

  const [filters, setFilters] = useState({
    question: availableQuestions[0],
  })

  const filteredReviews = reviews.filter((review = {}) => {
    const {
      question: questionFilter,
      stars: starsFilter
    } = filters;

    let passedFilters = true;

    if (questionFilter) {
      const { questionsAndAnswers = [] } = review;
      const questionsAndAnswersQs = questionsAndAnswers.map(({ question }) => question);
      if (!questionsAndAnswersQs.includes(questionFilter)) {
        passedFilters = false;
      }
    }

    if (hasStarsFilter && starsFilter) {
      const { stars } = review;
      if (stars > starsFilter || stars < (starsFilter - 1)) {
        passedFilters = false;
      }
    }

    return passedFilters;
  })

  const { question: focusedQuestion } = filters;

  return (
    <div>
      <ReviewFilters
        availableQuestions={availableQuestions}
        hasStarsFilter={hasStarsFilter}
        onFiltersChanged={(updateFilters) => setFilters({ ...filters, ...updateFilters })} 
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        {
          filteredReviews.length > 0 ?
          filteredReviews.map((reviewObj, index) => {
            return (
              <div key={reviewObj.id}>
                <Review 
                  {...reviewObj} 
                  focusedQuestion={focusedQuestion}
                  index={index}
                />
              </div>
            )
          })
          :
          <div style={{
            color: '#808080',
            fontSize: '14px',
            marginTop: '20px',
            textAlign: 'center',
            width: '100%',
          }}>
            {hasStarsFilter ? 'No reviews matched your selections.' : 'No reviews found for the selected question.'}
          </div>
        }
      </div>
    </div>
  )
}
