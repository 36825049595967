import React, { useRef } from 'react';
import { useGlobalData } from '../GlobalData'
import { TabButton, TextInput, DateInput, Accordion } from '@prosolve/common-components-ui'

export default ({
  tabs,
  countsPerTab,
  selectedTab,
  setSelectedTab,
  filters,
  setFilters,
}) => {
  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  const updateFilter = (field, val) => {
    setFilters({
      ...filters,
      [field]: val
    })
  }

  const itemsRef = useRef([]);
  const clearAll = () => {
    if (itemsRef && itemsRef.current && itemsRef.current.length > 0) {
      itemsRef.current.forEach(comp => {
        if (comp && comp.clearInput) {
          comp.clearInput();
        }
      });
    }
    setFilters({})
  }

  const labelStyle = { 
    marginRight: '15px',
    width: '130px'
  };

  const hasFilters = () => {
    let hasFilter = false;
    Object.keys(filters).forEach(filterKey => {
      if (Boolean(filters[filterKey])) {
        hasFilter = true;
      }
    });
    return hasFilter;
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '10px',
      borderBottom: '1px solid #dedede',
    }}>
      <div style={{
        display: 'flex',
        flexDirection: isMobileView ? 'column' : 'row',
        rowGap: isMobileView ? '15px' : '0px',
        columnGap: isMobileView ? '0px' : '15px',
        marginBottom: '20px'
      }}>
        {
          tabs.map(tab => {
            return (
              <TabButton
                key={tab}
                $selected={selectedTab === tab}
                onClick={() => {
                  if (selectedTab !== tab) {
                    setSelectedTab(tab)
                  }
                }}
              >
                {`${tab}${countsPerTab[tab] ? ` (${countsPerTab[tab]})` : ''}`}
              </TabButton>
            )
          })
        }
      </div>
      <div>
        <Accordion
          title={
            <div
              // style={{ 
              //   textDecoration: 'underline', 
              //   color: '#44ACE1', 
              //   fontSize: '15px', 
              //   fontStyle: 'italic' 
              // }}
            >
              <span
                style={{ 
                  textDecoration: 'underline', 
                  color: '#44ACE1', 
                  fontSize: '15px', 
                  fontStyle: 'italic' 
                }}
              >
                {'Additional Filters'}
              </span>
              {
                hasFilters() &&
                <span style={{
                  textDecoration: 'none',
                  marginLeft: '20px',
                  color: '#b1b5c4',
                  fontSize: '12px', 
                  fontStyle: 'italic' 
                }}>
                  {'*Filters currently applied'}
                </span>
              }
            </div>
          }
          defaultOpen={false}
          headerExpands={true}
          style={{ padding: '0px 5px 10px 5px' }}
          expandedStyle={{ padding: '0px 10px' }}
        >
          <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative'}}>
              <div 
                style={{ 
                  textDecoration: 'underline', 
                  color: '#44ACE1', 
                  fontSize: '13px', 
                  fontStyle: 'italic', 
                  cursor: 'pointer', 
                  position: 'absolute', 
                  top: '10px', 
                  right: '0px' 
                }}
                onClick={clearAll}
              >
                {'Clear All'}
              </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <div style={{ marginRight: '16px',  width: '180px' }}>Item Name</div>
              <TextInput
                style={{ width: '422px' }}
                ref={comp => itemsRef.current[0] = comp} 
                value={filters.itemName || ''}
                onChange={(val) => updateFilter('itemName', val)}
              />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <div style={{ marginRight: '16px',  width: '180px' }}>Contact Name</div>
              <TextInput
                style={{ width: '422px' }}
                ref={comp => itemsRef.current[1] = comp} 
                value={filters.contactName || ''}
                onChange={(val) => updateFilter('contactName', val)}
              />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <div style={{ marginRight: '16px',  width: '180px' }}>Contact Email</div>
              <TextInput
                style={{ width: '422px' }}
                ref={comp => itemsRef.current[2] = comp} 
                value={filters.contactEmail || ''}
                onChange={(val) => updateFilter('contactEmail', val)}
              />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <div style={labelStyle}>Created Date</div>
              <div style={{ marginRight: '15px' }}>From</div>
              <DateInput
                ref={comp => itemsRef.current[3] = comp} 
                value={filters.createdDateFrom || ''}
                onChange={(val) => updateFilter('createdDateFrom', val)}
              />
              <div style={{ margin: '0px 15px' }}>To</div>
              <DateInput
                ref={comp => itemsRef.current[4] = comp} 
                value={filters.createdDateTo || ''}
                onChange={(val) => updateFilter('createdDateTo', val)}
              />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <div style={labelStyle}>Requested Date</div>
              <div style={{ marginRight: '15px' }}>From</div>
              <DateInput
                ref={comp => itemsRef.current[5] = comp} 
                value={filters.requestedDateFrom || ''}
                onChange={(val) => updateFilter('requestedDateFrom', val)}
              />
              <div style={{ margin: '0px 15px' }}>To</div>
              <DateInput
                ref={comp => itemsRef.current[6] = comp} 
                value={filters.requestedDateTo || ''}
                onChange={(val) => updateFilter('requestedDateTo', val)}
              />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <div style={labelStyle}>Shipped Date</div>
              <div style={{ marginRight: '15px' }}>From</div>
              <DateInput
                ref={comp => itemsRef.current[7] = comp} 
                value={filters.shippedDateFrom || ''}
                onChange={(val) => updateFilter('shippedDateFrom', val)}
              />
              <div style={{ margin: '0px 15px' }}>To</div>
              <DateInput
                ref={comp => itemsRef.current[8] = comp} 
                value={filters.shippedDateTo || ''}
                onChange={(val) => updateFilter('shippedDateTo', val)}
              />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <div style={labelStyle}>Received Date</div>
              <div style={{ marginRight: '15px' }}>From</div>
              <DateInput
                ref={comp => itemsRef.current[9] = comp} 
                value={filters.receivedDateFrom || ''}
                onChange={(val) => updateFilter('receivedDateFrom', val)}
              />
              <div style={{ margin: '0px 15px' }}>To</div>
              <DateInput
                ref={comp => itemsRef.current[10] = comp} 
                value={filters.receivedDateTo || ''}
                onChange={(val) => updateFilter('receivedDateTo', val)}
              />
            </div>

          </div>
        </Accordion>
      </div>
    </div>
  )
}