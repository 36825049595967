import React, { useState, useEffect, useRef } from "react";
import { LoadingIcon, TextInput, Button } from '@prosolve/common-components-ui'
import styled from 'styled-components';
import useFetch from '../hooks/useFetch';
import { useNavigate } from "react-router-dom";

const Option = styled.div`
  font-size: 14px;
  padding: 8px 20px;
  cursor: pointer;
  background: ${props => props.$selected ? "#44ACE1" : "#ffffff"};
  color: ${props => props.$selected ? "#ffffff" : "#080808"};
  text-align: left;
  border-bottom: ${props => props.$hasBorderBottom ? '1px solid #dedede' : 'none'};
  &:hover {
    background: #44ACE1!important;
    color: #ffffff!important;
  }
`;

export default () => {
  const navigate = useNavigate();
  const fetch = useFetch();

  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [accounts, setAccounts] = useState([])
  const [selectedAccount, setSelectedAccount] = useState('')

  const fetchAccounts = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`shipments/accounts${searchValue ? `?name=${searchValue}` : ''}`, { method: 'GET' })
      const jsonData = await response.json();
      if (jsonData.error) {
        setAccounts([])
      } else {
        setAccounts(jsonData)
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e)
      setAccounts([])
      setIsLoading(false);
    }
  }

  const timeoutRef = useRef();
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      fetchAccounts();
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  useEffect(() => {
    if (selectedAccount) {
      const selectedAccountExistsInData = accounts.find(({ Id}) => Id === selectedAccount)
      if (!selectedAccountExistsInData) {
        setSelectedAccount('')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[accounts])

  const onSelectedAccountClick = async () => {
    if (selectedAccount && !isLoading) {
      navigate(`/shipmentTracking/account?id=${selectedAccount}`)
    }
  }

  const onAllAccountClick = () => {
    navigate('/shipmentTracking/account?id=ALL')
  }

  return (
    <div style={{
      display: 'flex',
      columnGap: '25px',
      // padding: '0px 3px',
      flex: 1,
      marginTop: '20px'
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '15px',
        // padding: '0px 3px',
        flex: 1,
        marginTop: '20px'
      }}>

      <div style={{ textAlign: 'center', fontSize: '15px', color: '#5a5a5a'}}>
        {'View shipments for'}
      </div>
       
      <Button 
        style={{ display: 'inline-flex' }} 
        onClick={onAllAccountClick} 
        $colored={true}>
        {'All Accounts'}
      </Button>

    </div>

    <div style={{
      borderRight: '1px solid #c4c4c4',
      height: '345px',
      marginTop: '50px'
    }}>
      &nbsp;
    </div>

    <div style={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: '15px',
      // padding: '0px 3px',
      flex: 1,
      marginTop: '20px'
    }}>

      <div style={{ textAlign: 'center', fontSize: '15px', color: '#5a5a5a'}}>
        {'View shipments for'}
      </div>

      <TextInput
        width='100%'
        value={searchValue}
        onChange={(val) => setSearchValue(val)}
        placeholder='Search Accounts'
      />

      <div
        style={{
          height: '250px',
          border: '1px solid #c4c4c4',
          borderRadius: '5px',
          overflow: 'auto',
        }}
      >
        {
          isLoading
          ?
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}>
            <LoadingIcon />
          </div>
          :
          accounts.length > 0
          ?
          accounts.map(({ Id, Name }) => {
            return (
              <Option
                key={Id}
                $selected={selectedAccount === Id} 
                onClick={() => setSelectedAccount(Id)}
              >
                {Name}
              </Option>
            )
          })
          :
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            fontSize: '15px', 
            color: '#5a5a5a'
          }}>
            No accounts found
          </div>
        }
      </div>

      <Button 
        style={{ display: 'inline-flex' }} 
        onClick={onSelectedAccountClick} 
        $colored={true}
        $disabled={isLoading || !selectedAccount}
      >
        {'Selected Account'}
      </Button>

    </div>

    </div>
  )
}