import React from 'react';
import { TextAreaInput } from '@prosolve/common-components-ui'

export default ({
  questionId,
  answers,
  setAnswers,
}) => {

  const answerObj =  answers[questionId] || {};
  const {
    answerText = ''
  } = answerObj;

  const onUpdateAnswer = (val) => {
    const updateObj = { answerText: val }
    setAnswers({
      ...answers,
      [questionId]: updateObj
    })
  }

  return (
    <TextAreaInput
      width='100%'
      value={answerText}
      onChange={onUpdateAnswer}
      placeholder=''
    />
  )
}