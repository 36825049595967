import React from 'react';
import ShippedItem from './ShippedItem';

export default ({
  items = [],
  missingItemsFormData,
  missingItemsFormDataIsReadOnly
}) => {
  return (
    <div>
      <div style={{
        fontSize: '15px',
        fontWeight: 'bold',
        marginBottom: '20px'
      }}>
        {'Shipped Materials'}
      </div>
      {
        items.length > 0
        ?
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px'}}>
        {
          items.map((item) => {
            return (
              <ShippedItem key={item.name} item={item} missingItemsFormData={missingItemsFormData} missingItemsFormDataIsReadOnly={missingItemsFormDataIsReadOnly} />
            )
          })
        }
        </div>
        :
        <div style={{ 
          color: '#565656',
          fontSize: '14px',
          marginTop: '20px'
        }}>
          {'No materials found for this shipment'}
        </div>
      }
    </div>
  )
}