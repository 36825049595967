import React, { useMemo } from 'react';
import { Accordion } from '@prosolve/common-components-ui'
import Review from './Review';

export default ({
  reviews = []
}) => {
  // Use memo prevents questionSet recalculation on filter changes
  const reviewsQuestionMap = useMemo(() => {
    const qs = {};
    reviews.forEach((review) => {
      const { questionsAndAnswers = [] } = review;
      questionsAndAnswers.forEach(({ question }) => {
        if (qs[question]) {
          qs[question].push(review)
        } else {
          qs[question] = [review]
        }
      });
    });
    return qs;
  }, [reviews]);

  const allQuestions = [
    'Would you like to write a testimonial for our website?',
    'What did you personally gain from attending the event?',
    'What was your favorite part of the experience?',
    'How could we improve our services?'
  ]

  const availableQuestions = allQuestions.filter(val => Object.keys(reviewsQuestionMap).indexOf(val) > -1);

  return (
    <div>
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        {
          availableQuestions.length > 0 ?
          availableQuestions.map((question, index) => {
            return (
              <div
                key={question}
                style={{
                  padding: '10px 0px',
                  borderBottom:  '1px solid #dedede'
                }}
              >
                <Accordion
                  title={<div style={{ fontWeight: 'bold' }}>{question}</div>}
                  defaultOpen={index === 0}
                >
                  {
                    reviewsQuestionMap[question].map((reviewObj, index) => {
                      return (
                        <div key={reviewObj.id}>
                          <Review 
                            {...reviewObj} 
                            focusedQuestion={question}
                            isFirst={index === 0}
                          />
                        </div>
                      )
                    })
                  }
                </Accordion>
              </div>
            )
          })
          :
          <div style={{
            color: '#808080',
            fontSize: '14px',
            marginTop: '20px',
            textAlign: 'center',
            width: '100%',
          }}>
            {'No reviews found.'}
          </div>
        }
       
        {/* {
          filteredReviews.length > 0 ?
          filteredReviews.map(reviewObj => {
            return (
              <div key={reviewObj.id}>
                <Review 
                  {...reviewObj} 
                  focusedQuestion={focusedQuestion}
                />
              </div>
            )
          })
          :
          <div style={{
            color: '#808080',
            fontSize: '14px',
            marginTop: '20px',
            textAlign: 'center',
            width: '100%',
          }}>
            {hasStarsFilter ? 'No reviews matched your selections.' : 'No reviews found for the selected question.'}
          </div>
        } */}
      </div>
    </div>
  )
}
