import React from "react";
import FeedbackWizard from "../../../FeedbackWizardView/FeedbackWizard/FeedbackWizard";

export default ({
  assessmentName,
  previewData,
  onClose
}) => {
  return (
    <div style={{ 
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: '0px',
      left: '0px',
      background: '#b8b8b8b8',
      zIndex: '100000'
    }}>
      <div style={{ 
        padding: '0',
        height: 'calc(100vh)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <FeedbackWizard isPreview={true} assessmentName={assessmentName} previewData={previewData} hasCloseButton={true} onClose={onClose} onSuccess={onClose} />
      </div>
    </div>
  )
}