import React, { useState, useEffect, useMemo } from "react";
import useFetch from '../../hooks/useFetch';
import QuestCodesFilter from "./QuestCodesFilter";
import QuestCodesDisplay from "./QuestCodesDisplay";

import ProvisionNewQuestCodesModal from '../ProvisionNewQuestCodesModal';

export default () => {
  const fetch = useFetch();

  const onProvisonNewCodes = () => {
    setShowProvisionNewQuestCodesModal(true)
  }

  const [showProvisionNewQuestCodesModal, setShowProvisionNewQuestCodesModal] = useState(false)

  const [isLoading, setIsLoading] = useState(true)
  const [questCodes, setQuestCodes] = useState([])

  const [filters, setFilters] = useState({
    productType: 'Adventures Apex',
    provisionGroupNumber: '',
    specificCode: ''
  })

  const fetchQuestCodes = async () => {
    try {
      // api/quest/codes
      setIsLoading(true);
      const response = await fetch(`quest/codes`, { method: 'GET' })
      const jsonData = await response.json();
      if (jsonData.error) {
        setQuestCodes([])
      } else {
        setQuestCodes(jsonData)
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e)
      setQuestCodes([])
      setIsLoading(false);
    }
  }

  const extractFileName = (response) => {
    const headers = response.headers;
    const contentDisposition = headers.get('Content-Disposition');
    const match = contentDisposition && contentDisposition.match(/filename=(.+)/);
    return match ? match[1] : 'data.csv'; // Default filename if not found
  }

  const downloadQuestCodes = async (pType, pGroupNum) => {
    try {
      const response = await fetch(`quest/codes/download?productType=${pType}&provisionGroupNumber=${pGroupNum}`, { method: 'GET' })

      const blob = await response.blob(); 

      const fileName = extractFileName(response)

      const url = window.URL.createObjectURL(blob);

      // Create an anchor element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // Set filename for download
      document.body.appendChild(link);

      // Simulate click on the anchor element to trigger download
      link.click();

      // Cleanup: remove the anchor and revoke the URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchQuestCodes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Use memo prevents provisionGroupNumberSet recalculation on filter changes
  const provisionGroupNumberSet = useMemo(() => {
    const set = [];
    questCodes.forEach(({ provisionGroupNumber }) => {
      if (provisionGroupNumber && set.indexOf(provisionGroupNumber) < 0) {
        set.push(provisionGroupNumber)
      }
    });
    return set;
  }, [questCodes]);

  const filteredQuestCodes = questCodes.filter(({ 
    productType = '', 
    code = '',
    provisionGroupNumber = ''
  }) => {
    let passedFilters = true;

    const productTypeFilter = filters.productType === 'Unprovisioned Codes' ? '' : filters.productType;
    const provisionGroupNumberFilter = filters.provisionGroupNumber;
    const specificCodeFilter = filters.specificCode ? filters.specificCode.toUpperCase() : '';

    if (productTypeFilter !== productType) {
      passedFilters = false;
    }

    if (provisionGroupNumberFilter && provisionGroupNumberFilter !== provisionGroupNumber) {
      passedFilters = false;
    }

    if (specificCodeFilter && code.toUpperCase().indexOf(specificCodeFilter) < 0) {
      passedFilters = false;
    }

    return passedFilters;
  })

  return (
    <>
    {
      showProvisionNewQuestCodesModal && 
      <ProvisionNewQuestCodesModal 
        downloadQuestCodes={downloadQuestCodes}
        fetchQuestCodes={fetchQuestCodes}
        onClose={() => setShowProvisionNewQuestCodesModal(false)} 
      /> 
    }
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: '15px',
      flex: 1,
      marginTop: '10px'
    }}>

      <div style={{ textAlign: 'center', fontSize: '15px', color: '#5a5a5a'}}>
        {'Manage Quest Codes'}
      </div>

      <QuestCodesFilter 
        filters={filters} 
        setFilters={setFilters} 
        provisionGroupNumberSet={provisionGroupNumberSet}
        downloadQuestCodes={downloadQuestCodes}
        onProvisonNewCodes={onProvisonNewCodes} 
      />

      <QuestCodesDisplay 
        isLoading={isLoading}
        filters={filters}
        questCodes={filteredQuestCodes}
      />

    </div>
    </>
  )
}