import React, { useEffect }  from "react";
import useForm from '../../../../hooks/useForm';
import { FormField, Accordion } from '@prosolve/common-components-ui'
import { FaRegTrashAlt } from 'react-icons/fa';

export default ({
  answerOption = {},
  isReadOnly,
  type,
  onChange,
  onDelete
}) => {
  const formSchema = {
    requiredFields: ['id', 'answerOption'],
    optionalFields: ['image', 'textInput', 'hideOptionText', 'number'],
    initData: answerOption
  }

  const formData = useForm(formSchema)

  useEffect(() => {
    onChange(formData.values)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.values])

  // Below is causing issue so not using for now ---
  // What happens is if multiple answerOptions...last one overwrites changes from all others due to the way state is set

  // useEffect(() => {
  //   // Clear values not in type
  //   const clearValues = {};

  //   if (type === 'multitext') {
  //     clearValues.textInput = '';
  //     clearValues.hideOptionText = false;
  //   } else if (['radio','checkbox'].includes(type)) {
  //     clearValues.number = false;
  //   }

  //   formData.setValues({ ...formData.values, ...clearValues })
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [type])

  const hasOptional = ['radio','checkbox'].includes(type)
   ? (answerOption.image || answerOption.textInput || answerOption.hideOptionText)
   : (answerOption.image || answerOption.number);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #dedede',
      borderRadius: '5px',
      background: '#fdfdfd',
      padding: '10px',
      marginBottom: '10px'
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        columnGap: '15px'
      }}>
        <FormField
          fieldId='answerOption'
          type='text'
          // label='Answer Option'
          disabled={isReadOnly}
          style={{ flex: 1 }}
          formData={formData}
        />
        <div>
          <FaRegTrashAlt
            onClick={() => !isReadOnly && onDelete(formData.values)}
            style={{
              position: 'relative',
              top: '4px',
              right: '4px',
              fontSize: '20px',
              cursor: isReadOnly ? 'default' : 'pointer',
              color: isReadOnly ? '#7E7E7E' : '#f85c5c'
            }}
          />
        </div>
      </div>
      <Accordion
        title={
          <div style={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative'
          }}>
            <div style={{ color: '#565656', fontSize: '13px', fontStyle: 'italic' }}>{'Optional Properties'}</div>
            <div style={{
              flex: 1,
              borderBottom: '1px solid #c4c4c4',
              position: 'relative',
              top: '-8px',
              left: '15px',
              marginRight: '42px'
            }}>
              &nbsp;
            </div>
          </div>
        }
        headerExpands={true}
        defaultOpen={hasOptional}
        style={{
          padding: '5px 6px 5px 10px'
        }}
        expandedStyle={{
          padding: '5px 6px 5px 10px'
        }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          columnGap: '15px',
          rowGap: '15px',
          marginTop: '10px',
          marginBottom: '10px',
          marginRight: '42px'
        }}>
          <FormField
            fieldId='image'
            type='text'
            label='Image URL'
            disabled={isReadOnly}
            style={{ flex: 1 }}
            formData={formData}
          />
          {
            ['radio', 'checkbox'].includes(type) &&
            <FormField
              fieldId='textInput'
              type='text'
              label='Text Input Description'
              disabled={isReadOnly}
              style={{ flex: 1 }}
              formData={formData}
            />
          }
          {
            ['radio', 'checkbox'].includes(type) &&
            <FormField
              fieldId='hideOptionText'
              type='checkbox'
              label='Hide Option Text'
              disabled={isReadOnly}
              style={{ flex: 1 }}
              formData={formData}
            />
          }
          {
            ['multitext'].includes(type) &&
            <FormField
              fieldId='number'
              type='checkbox'
              label='Is Number'
              disabled={isReadOnly}
              style={{ flex: 1 }}
              formData={formData}
            />
          }
        </div>
      </Accordion>
    </div>
  )
}