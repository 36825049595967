import React from 'react';
import { Stars } from '@prosolve/common-components-ui'
import styled from 'styled-components';
import { useGlobalData } from '../../GlobalData';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  // border: 1px solid #c4c4c4;
  // border-radius: 5px;
  width: ${props => props.$isMobileView ? "auto" : "150px"};
  flex: ${props => props.$isMobileView ? "1" : "none"};
  height: 70px;
  align-items: flex-start;
  justify-content: center;
  /* padding: 20px;
  &:hover {
    box-shadow: 1px 1px 3px 1px rgba(68, 172, 225, 0.7)
  } */
`;

export default ({
  stars,
  // animationDelay
}) => {

  // Convert stars out of 10 to stars out of 5 (and round to nearest 1 decimal place)
  const starsDisplay = Math.round( ( stars / 2 ) * 10 ) / 10;

  const globalData = useGlobalData();
  const { isMobileView } = globalData;
  return (
    // <ScaleInWrapper animationDelay={animationDelay}>
    <Wrapper $isMobileView={isMobileView}>
      <div style={{
        // marginBottom: '5px',
        fontWeight: 'bold',
        fontSize: '18px',
        color: '#565656',
        textAlign: 'left'
      }}>
        Overall Rating
      </div>
      <div style={{
        marginTop: '3px',
        marginBottom: '2px',
        fontWeight: 'bold',
        fontSize: '34px',
        color: '#565656',
        textAlign: 'left'
      }}>
        {starsDisplay}
      </div>
      <Stars stars={stars} />
    </Wrapper>
    // </ScaleInWrapper>
  )
}
