import React, { useState, useEffect } from 'react';
import DownloadCsvView from './DownloadCsvView';
import DataExplorerView from './DataExplorerView/DataExplorerView';
import useFetch from '../../hooks/useFetch';

export default () => {
  const [isLoading, setIsLoading] = useState(true)
  const [assessmentNames, setAssessmentNames] = useState([]);

  const fetch = useFetch();

  const fetchAssessmentNames = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`assessment/list`, { method: 'GET' })
      const jsonData = await response.json();
      if (jsonData.error) {
        setAssessmentNames([])
      } else {
        setAssessmentNames(jsonData)
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e)
      setAssessmentNames([])
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchAssessmentNames()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tabs = ['Data Explorer', 'Download CSV']

  const [selectedTab, setSelectedTab] = useState('Data Explorer')

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        columnGap: '15px',
        paddingLeft: '20px',
        marginTop: '10px',
        borderBottom: '1px solid #C2C9D1'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          rowGap: '15px',
          columnGap: '15px',
          flexWrap: 'wrap'
        }}>
          {
            tabs.map(tab => {
              const isSelected = selectedTab === tab;
              return (
                <div
                  key={tab}
                  style={{
                    padding: '8px 12px 6px 12px',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    color: isSelected ? '#0280c6' : '#7e7e7e',
                    borderBottom: isSelected ? '2px solid #0280c6' : '2px solid transparent'
                  }}
                  onClick={() => {
                    if (selectedTab !== tab) {
                      setSelectedTab(tab)
                    }
                  }}
                >
                  {tab}
                </div>
              )
            })
          }
        </div>
      </div>
      {
          selectedTab === 'Download CSV' ?
          <DownloadCsvView isLoading={isLoading} assessmentNames={assessmentNames} />
          :
          selectedTab === 'Data Explorer' ?
          <DataExplorerView />
          :
          <></>
        }
    </div>
  )
}