import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { CiWarning } from 'react-icons/ci';
import { TextInput } from '@prosolve/common-components-ui'

export default ({
  missingItemsFormData,
  missingItemsFormDataIsReadOnly,
  onCloseForm
}) => {
  const {
    values,
    setValues
  } = missingItemsFormData;

  return (
    <div style={{
      border: '1px solid #dedede',
      borderRadius: '5px'
    }}>
      <div style={{
        background: '#f8f9fc',
        borderBottom: '1px solid #dedede',
        padding: '10px 10px',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        display: 'flex',
        fontSize: '12px',
        color: '#565656',
        fontWeight: 'bold',
        alignItems: 'center'
      }}>
        <CiWarning style={{ fontSize: '22px', color: '#ef0c0c' }} />
        <div style={{ flex: 1, fontSize: '15px', marginLeft: '5px' }}>Report Missing Items</div>
        {!missingItemsFormDataIsReadOnly && <FaTimes style={{ fontSize: '18px', cursor: 'pointer' }} onClick={onCloseForm} /> }
      </div>
      <div style={{
        padding: '20px',
        display: 'flex'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '15px',
          flex: 1
        }}>
          <div>
            {'Enter the number of items your received in your shipment below. If the item was not included in the shipment, enter 0 (zero).'}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={{
              marginBottom: '5px',
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#565656',
            }}>
              Description
            </div>
            <TextInput value={values.missingNote} onChange={(missingNote) => setValues({ ...values, missingNote})} disabled={missingItemsFormDataIsReadOnly} />
          </div>
        </div>
      </div>
    </div>
  )
}