import React from "react";
import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 14px;
  padding: 8px 20px;
  cursor: default;
  background: #ffffff;
  color: #080808;
  text-align: left;
  padding: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dedede; // ${props => props.$hasBorderBottom ? '1px solid #dedede' : 'none'};
  word-wrap: break-word;
`;

export default ({
  answers
}) => {

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: '15px',
      flex: 1
    }}>

      <div
        style={{
          height: '400px',
          borderBottom: '1px solid #c4c4c4',
          overflow: 'auto',
        }}
      >
        {
          answers
            .map((answer, index) => {
              return (
                <Wrapper
                  key={index}
                >
                  <div style={{ flex: 1, fontSize: '16px', wordBreak: 'break-all' }}>{answer}</div>
                </Wrapper>
              )
            })
        }
      </div>

    </div>
  )
}